import React, { useState } from "react";
import axios from "axios";

const App = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");

  const getUsers = () => {
    axios
      .get("https://api.taginfosolutions.com/api/v1/users", {
        withCredentials: true, // Ensures cookies are sent with the request
      })
      .then((res) => {
        console.log("zzzzzzzzzzz", res?.data);
        console.log("zzzzzzzzzzz", res?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <h2>Login</h2>
      <h3>Users</h3>
      <button onClick={getUsers}>Get Users</button>
    </div>
  );
};

export default App;
